<template>
  <v-container
  class="section">
    <v-card>
        <v-card-title>{{ $t(title) }}</v-card-title>
        <v-card-text v-html="$t(text)"></v-card-text>
        
      <Carousel
      :items="items"
      ></Carousel>

    </v-card>
  </v-container>
</template>

<script>
import Carousel from '@/components/base/Carousel'
export default {
  components: {
    Carousel,
  },
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>