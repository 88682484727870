<template>
    <v-container
    class="section">
        <v-card>
            <v-card-title>{{ $t(title) }}</v-card-title>
            <v-card-text v-html="$t(text)"></v-card-text>
                <div v-if="type==='external'">
                    <AlertExternal
                    :introSeeAlso="introSeeAlso"
                    :seeAlso="seeAlso"
                    :link="link"
                    ></AlertExternal>
                </div>
                <div v-else>
                    <AlertInternal
                    :introSeeAlso="introSeeAlso"
                    :seeAlso="seeAlso"
                    :link="link"
                    ></AlertInternal>
                </div>
        </v-card>
    </v-container>
</template>


<script>
import AlertExternal from '@/components/base/AlertExternal';
import AlertInternal from '@/components/base/AlertInternal';
export default {
    components: {
        AlertExternal,
        AlertInternal
    },
    props: ['title', 'text', 'type', 'introSeeAlso', 'seeAlso', 'link'],
}
</script>