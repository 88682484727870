<template>
    <v-container>
        <base-section-heading
        title="about.site.title">
        </base-section-heading>

        <SectionWithAlert
        v-for="section in sectionsWithAlert"
        :key="section.key"
        :id="section.id"
        :title="section.title"
        :text="section.text"
        :introSeeAlso="section.introSeeAlso"
        :seeAlso="section.seeAlso"
        :link="section.link"
        :type="section.type"
        ></SectionWithAlert>

        <SectionWithCarousel
        v-for="section in sectionsWithCarousel"
        :key="section.key"
        :id="section.id"
        :title="section.title"
        :text="section.text"
        :items="section.items"
        ></SectionWithCarousel>
        
        <Section
        v-for="section in sections"
        :key="section.key"
        :id="section.id"
        :title="section.title"
        :text="section.text"
        ></Section>

    </v-container>
</template>

<script>
import SectionWithAlert from '@/components/docs/SectionWithAlert'
import SectionWithCarousel from '@/components/docs/SectionWithCarousel'
import Section from '@/components/docs/Section'
export default {
    components: {
      SectionWithAlert,
      SectionWithCarousel,
      Section
    },
    data() {
        return {
            sectionsWithAlert: [
                { 
                    key: 1,
                    id: 'benchmark-section',    
                    title: 'about.site.section-1.title',
                    text: 'about.site.section-1.text',
                    introSeeAlso: 'about.site.section-1.introSeeAlso',
                    seeAlso: 'about.site.section-1.seeAlso',
                    link: 'about.site.section-1.link',
                    type: 'external'
                },
            ],
            sectionsWithCarousel: [
                {
                    key: 2,
                    id: 'architecture-section',
                    title: 'about.site.section-2.title',
                    text: 'about.site.section-2.text',
                    items: [
                        {
                            src: 'site-map.svg'
                        },
                        {
                            src: 'wf-home.svg',
                        },
                        {
                            src: 'wf-works.svg',
                        },
                        {
                            src: 'wf-indexes.svg',
                        },
                        {
                            src: 'wf-index-example.svg',
                        },
                        {
                            src: 'wf-contents.svg',
                        },
                        {
                            src: 'wf-contents-example.svg',
                        },
                        {
                            src: 'wf-search.svg',
                        },
                        {
                            src: 'wf-work-example-text.svg',
                        },
                        {
                            src: 'wf-work-example-metadata.svg',
                        },
                        {
                            src: 'wf-project.svg',
                        },
                        {
                            src: 'wf-documentation-example.svg',
                        },
                    ]
                },
            ],
            sections: [
                {
                    key: 3,
                    id: 'realization-section',
                    title: 'about.site.section-3.title',
                    text: 'about.site.section-3.text'
                },
            ]
        }
    }
}
</script>

<style scoped>
a:hover {
    background-color: lightyellow ;
    text-decoration: none
}
div >>> a:hover {
    background-color: lightyellow ;
    text-decoration: none
}
</style>
